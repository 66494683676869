<template>
  <div class="create-transaction">
    <p class="text-header">Create Transaction</p>

    <div class="body">
      <div class="header-title">Service</div>
      <b-row>
        <b-col cols="6">
          <div class="mx-1">
            <AutocompleteCustom
              v-model="form.eventGroupId"
              ref="AutocompleteCustomEventGroup"
              id="eventGroupId"
              label="Service"
              placeholder="Service"
              dataListID="dataListID"
              valueField="id"
              showValue=""
              :optionsList="optionEventGroup"
              @handleInput="handleInputEventGroup"
              absolute
              :isValidate="$v.form.eventGroupId.$error"
              :v="$v.form.eventGroupId"
            />
          </div>
        </b-col>
        <b-col cols="6">
          <div class="mx-1">
            <AutocompleteCustom
              v-model="form.eventId"
              ref="AutocompleteCustomEvent"
              id="eventId"
              label="Branch"
              placeholder="Branch"
              dataListID="dataListID"
              valueField="id"
              showValue=""
              :optionsList="optionEvent"
              @handleInput="handleInputEvent"
              absolute
              :isValidate="$v.form.eventId.$error"
              :v="$v.form.eventId"
              :showNodata="false"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="tw-mt-10"
          ><div class="header-title">Booking information</div></b-col
        >
        <b-col cols="6">
          <div
            v-if="!form.eventId"
            class="tw-flex tw-justify-center tw-items-center box-calendar"
          >
            Please select branch
          </div>
          <div v-else>
            <Calendar :form="form" @getRoundTime="getRoundTime" />
          </div>
          <div
            v-if="$v.form.date.$error === true"
            class="tw-text-red-500 tw-text-center"
          >
            Please select date
          </div>
        </b-col>
        <b-col cols="6">
          <p class="tw-font-bold tw-text-base">Round</p>
          <div class="tw-flex tw-w-full tw-justify-center tw-mb-4">
            <div v-if="form.date === null" class="">Please select date</div>
            <div class="" v-else>
              <div class="time" v-if="!isLoadingRoundTime">
                <div
                  class="box-time"
                  :class="handleClassRoundTime(item)"
                  v-for="item in optionRoundTime"
                  :key="item.id"
                >
                  <div class="" @click="handleClickRoundTime(item)">
                    {{ item.timeStart }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="$v.form.roundTimeEventId.$error === true"
            class="tw-text-red-500 tw-text-center tw-mb-4"
          >
            Please select time
          </div>

          <div class="number-people mx-1">
            <div class="header-people" @click="isCollap = !isCollap">
              <p class="tw-font-bold">Ticket</p>
              <b-icon icon="chevron-down" class="icon-close" />
            </div>
            <p class="tw-text-center" v-if="!form.roundTimeEventId">
              Please select time
            </p>
            <b-collapse
              class="handle-people"
              id="collapse-people"
              v-model="isCollap"
            >
              <div
                v-for="(product, index) in optionProduct?.productList"
                :key="`product${index}`"
              >
                <div class="handle-count-people">
                  <p class="g-text">
                    {{ product.productName }}
                  </p>
                  <div class="box-count">
                    <b-button
                      v-if="optionProduct.lockProductId < 0"
                      class="btn plus"
                      @click="handlePeople(product, true)"
                      :disabled="product.selectPeople !== null"
                    >
                      +
                    </b-button>

                    <p
                      class="count"
                      :class="`${
                        optionProduct.lockProductId < 0 ? ' tw-text-right' : ''
                      }`"
                    >
                      {{ product.selectPeople }}
                    </p>
                    <b-button
                      v-if="optionProduct.lockProductId < 0"
                      class="btn minuss"
                      @click="handlePeople(product, false)"
                      :disabled="product.selectPeople !== null"
                    >
                      -
                    </b-button>
                  </div>
                </div>
              </div></b-collapse
            >
          </div>
          <div class="promocode mx-1">
            <div class="tw-mt-10">
              <div class="box-price">
                <p class="g-text font-300 tw-font-bold">Total</p>
                <p class="g-text font-300">
                  ฿ {{ priceCalculated?.subTotal || 0 }}
                </p>
              </div>
              <div class="box-price">
                <p class="g-text font-300 tw-font-bold">Discount</p>
                <p class="g-text font-300">
                  ฿ {{ priceCalculated?.discount || 0 }}
                </p>
              </div>
              <div class="box-price">
                <p class="g-text tw-font-bold">GrandTotal</p>
                <p class="g-text">฿ {{ priceCalculated?.total || 0 }}</p>
              </div>
            </div>
          </div>

          <div v-if="validateProduct" class="tw-text-red-500">
            Please select product
          </div>

          <div class="my-3 mx-1">
            <InputSelect
              v-model="paymentTypeId"
              :options="optionPaymentType"
              title="PaymentType"
              name="PaymentType"
              isRequired
              valueField="paymentTypeId"
              textField="name"
              @onDataChange="($event) => (paymentTypeId = $event)"
              :isValidate="$v.paymentTypeId.$error"
              :v="$v.paymentTypeId"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled
                  >-- Select PaymentType --
                </b-form-select-option>
              </template>
            </InputSelect>
          </div>
        </b-col>
      </b-row>

      <b-row
        ><b-col cols="12">
          <div class="header-title tw-mt-4">User Info</div>
          <div class="mx-1">
            <UserInfo
              :formCustomer="formCustomer"
              :v="$v.formCustomer"
              @clearOption="clearOption"
            />
          </div>
          <div class="reserve-box mx-1">
            <div class="reserve-header">
              <p class="tw-font-bold">Additional Note</p>
            </div>
            <b-form-textarea
              v-model="form.note"
              id="note"
              placeholder="Additional Note"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div> </b-col
      ></b-row>
    </div>

    <div
      class="tw-sticky tw-bottom-0 tw-bg-white tw-z-40 tw-w-full tw-h-12 tw-px-3 mx-1"
    >
      <div class="footer-event-setting">
        <b-button class="btn-filter cancel" @click="handleBack">
          Cancel
        </b-button>
        <b-button
          class="btn-filter save"
          :disabled="isLoading"
          @click="handleCreate"
        >
          Save
          <b-spinner
            class="m-0 ml-1"
            label="Spinning"
            small
            v-if="isLoading"
          ></b-spinner>
        </b-button>
      </div>
    </div>
    <ModalAlertText :textModal="textModal" :arrModal="[]" :size="'lg'" />
  </div>
</template>

<script>
import AutocompleteCustom from "@/components/inputs/AutocompleteCustom";
import Calendar from "./component/calendar";
import UserInfo from "./component/userInfo.vue";

import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import {
  required,
  email,
  minLength,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "createTransactionPage",
  components: { AutocompleteCustom, Calendar, UserInfo, ModalAlertText },
  data() {
    return {
      optionEventGroup: [],
      optionEvent: [],
      optionRoundTime: [],
      optionPaymentType: [],
      optionProduct: {},
      priceCalculated: {},

      form: {
        eventTypeId: 1,
        eventDateId: 0,
        eventId: null,
        promocode: "",
        note: "",
        roundTimeEventId: null,
        date: null,
        openEventDate: {
          year: null,
          month: null,
          day: null,
        },
      },
      paymentTypeId: null,

      formCustomer: {
        firstname: "",
        lastname: "",
        email: "",
        telephone: "",
      },

      isLoadingRoundTime: false,
      isCollap: true,
      isLoading: false,

      validateProduct: false,

      timer: null,
      textModal: "",
    };
  },
  validations() {
    return {
      paymentTypeId: { required },

      formCustomer: {
        firstname: { required },
        lastname: { required },
        email: { required, email },
        telephone: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        userId: { required },
      },

      form: {
        eventGroupId: { required },
        eventId: { required },
        date: { required },
        roundTimeEventId: { required },
      },
    };
  },
  watch: {
    "form.eventGroupId"(val) {
      if (val) {
        this.$refs.AutocompleteCustomEvent.clearInput();
        this.clearOption();
        this.getEventOption();
        this.form.eventId = null;
      }
    },
    "form.eventId"(val) {
      if (val) {
        const existEvent = this.optionEvent.find((x) => x.eventId === val);
        const { openEventDate } = existEvent;
        this.form.openEventDate = openEventDate;
        this.clearOption();
      }
    },
  },
  mounted() {
    this.getEventGroupOption();
    this.getOptionPaymentType();
  },
  methods: {
    async insertUserCart() {
      const arrProductId = this.addFormProduct();
      const payload = {
        ...this.form,
        bookingDate: this.form.date,
        productId: arrProductId,
        userId: this.formCustomer.userId,
      };

      const respone = await this.$services.transaction.insertUserCart(payload);

      return respone;
    },

    async updateUserCartInfomation(refId) {
      const payload = {
        ...this.formCustomer,
        refId: refId,
        note: this.form.note,
        hasBilling: false,
      };

      const respone = await this.$services.transaction.updateUserCartInfomation(
        payload
      );
      return respone;
    },
    async submitBooking(refId) {
      const payload = {
        refId: refId,
        paymentTypeId: this.paymentTypeId,
      };
      const respone = await this.$services.transaction.submitBooking(payload);
      return respone;
    },
    async getEventGroupOption(keyword) {
      const respone = await this.$services.master.getOptionEventGroup(keyword);
      if (respone.result === 1) {
        this.optionEventGroup = respone.detail;
      }
    },
    async getOptionPaymentType() {
      const respone = await this.$services.master.getPaymentOption();
      if (respone.result === 1) {
        this.optionPaymentType = respone.detail;
      }
    },
    async getEventOption(keyword) {
      const payload = {
        eventGroupId: this.form.eventGroupId,
        eventName: keyword,
        previewOrPublish: 1,
      };
      const respone = await this.$services.event.getEventList(payload);

      if (respone.result === 1) {
        this.optionEvent = respone.detail.data.map((x) => {
          return {
            ...x,
            name: x.venueName,
          };
        });
      }
    },
    async getRoundTime(date) {
      this.isLoadingRoundTime = true;
      this.form.roundTimeEventId = null;
      this.priceCalculated = {};
      this.optionProduct = {};
      this.form.date = date;
      const respone = await this.$services.eventgroup.getRoundTime(
        this.form.date,
        this.form.eventTypeId,
        this.form.eventId
      );
      if (respone.result === 1) {
        this.optionRoundTime = respone.detail;
      }
      this.isLoadingRoundTime = false;
    },
    async getProduct() {
      this.priceCalculated = {};
      const respone = await this.$services.eventgroup.getProduct(
        this.form.date,
        this.form.eventTypeId,
        this.form.eventId,
        this.form.eventDateId,
        this.form.roundTimeEventId
      );
      if (respone.result === 1) {
        this.optionProduct = respone.detail;
        this.optionProduct.productList = this.optionProduct.productList.map(
          (x) => {
            let selectPeople = 0;
            if (this.optionProduct.lockProductId) {
              if (x.productId === this.optionProduct.lockProductId) {
                selectPeople = 1;
              }
            }
            return {
              ...x,
              selectPeople: selectPeople,
            };
          }
        );
        if (this.optionProduct.lockProductId) {
          this.calculatePrice();
        }
      }
    },
    async calculatePrice() {
      const arrProductId = this.addFormProduct();
      if (arrProductId === [] || arrProductId.length === 0) {
        this.validateProduct = true;
      } else {
        this.validateProduct = false;
      }
      const payload = {
        ...this.form,
        productId: arrProductId,
      };
      const respone = await this.$services.eventgroup.calculatePrice(payload);
      if (respone.result === 1) {
        this.priceCalculated = respone.detail;
      }
    },
    handleCreate() {
      this.$v.paymentTypeId.$touch();
      this.$v.formCustomer.$touch();
      this.$v.form.$touch();

      if (
        this.$v.paymentTypeId.$error ||
        this.$v.formCustomer.$error ||
        this.$v.form.$error ||
        this.validateProduct
      ) {
        return;
      }
      this.isLoading = true;
      this.insertUserCart().then((respone) => {
        if (respone.result === 1) {
          const { refId } = respone.detail;

          this.updateUserCartInfomation(refId).then((res) => {
            this.submitBooking(refId).then((responeSubmit) => {
              if (responeSubmit.result === 1) {
                this.$router.push(
                  `/transaction/details/${responeSubmit.detail.transactionRefId}`
                );
              }
            });
          });
        } else {
          this.openModalAlertText(
            `${respone.detail.title} ${respone.detail.description}`
          );
        }
        this.isLoading = false;
      });
    },
    addFormProduct() {
      let arrProductId = [];
      if (this.optionProduct.lockProductId) {
        arrProductId.push(this.optionProduct.lockProductId);
      } else {
        for (let i = 0; i < this.optionProduct.productList.length; i++) {
          const x = this.optionProduct.productList[i];

          if (x.selectPeople != 0) {
            for (let j = 0; j < x.selectPeople; j++) {
              arrProductId.push(x.productId);
            }
          }
        }
      }
      return arrProductId;
    },
    handlePeople(obj, isPlus) {
      let { selectPeople } = obj;

      if (isPlus) {
        obj.selectPeople++;
      } else {
        if (selectPeople == 0) {
          return;
        }

        obj.selectPeople--;
      }

      this.calculatePrice();
    },
    handleClickRoundTime(val) {
      this.isLoadingRoundTime = true;
      const { roundTimeEventId } = val;

      this.form.roundTimeEventId = roundTimeEventId;
      this.isLoadingRoundTime = false;
      this.getProduct();
    },
    handleInputEventGroup(val) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getEventGroupOption(val);
      }, 1000);
    },
    handleInputEvent(val) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getEventOption(val);
      }, 1000);
    },
    handleClassRoundTime(obj) {
      let newClass = [];
      let { disabled, roundTimeEventId } = obj;

      if (this.form.roundTimeEventId == roundTimeEventId) {
        newClass.push("select");
      }

      if (disabled == 0) {
        newClass.push("can");
      } else if (disabled == 1) {
        newClass.push("not");
      }

      newClass = newClass.join(" ");

      return newClass;
    },
    handleBack() {
      this.$router.back();
    },
    clearOption() {
      this.isLoadingRoundTime = false;
      this.optionRoundTime = [];
      this.optionProduct = {};
      this.priceCalculated = {};
      this.isLoadingRoundTime = true;
    },
    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
  },
};
</script>

<style lang="scss" scoped>
.create-transaction {
  .box-calendar {
    height: 86%;
  }
  .footer-event-setting {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
  .number-people {
    .header-people {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .handle-people {
      margin-top: 8px;

      .handle-count-people {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;

        .box-count {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // min-width: 75px;

          .count {
            margin: 0px 5px;
          }

          .btn {
            align-items: center;
            background-color: var(--secondary-color);
            border: none;
            border-radius: 50%;
            color: #fff;
            display: flex;
            height: 25px;
            justify-content: center;
            width: 20px;
          }
        }
      }
    }
  }

  .promocode {
    margin-top: 10px;

    .header-promocode {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .box-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
  .header-title {
    padding: 15px;
    background: var(--secondary-color);
    color: var(--font-color);
    margin-bottom: 20px;
    .title {
      color: white;
    }
  }
  .body {
    padding: 15px;
    background: white;
  }
  .time {
    display: flex;
    flex-wrap: wrap;

    .box-time {
      border-radius: 20px;
      padding: 0 10px;
      margin: 0px 10px 10px 0px;

      &.select {
        cursor: pointer;

        color: white !important;

        border: 1px solid var(--secondary-color);
        background: var(--secondary-color);
      }

      &.can {
        cursor: pointer;

        p {
          color: var(--secondary-color);
        }

        border: 1px solid var(--secondary-color);
        color: var(--secondary-color);
      }

      &.not {
        cursor: default;

        p {
          color: var(--secondary-color);
        }

        border: 1px solid var(--secondary-color);
        color: var(--secondary-color);
        opacity: 0.3;
      }
    }
  }
  .item-roundtime:hover {
    background-color: var(--secondary-color);
    color: white;
  }
  .item-roundtime:active {
    background-color: var(--secondary-color);
    color: white;
  }
  .item-roundtime:focus {
    background-color: var(--secondary-color);
    color: white;
  }
}
// ! override
.btn-filter {
  width: 100%;
  max-width: 150px;

  &.cancel {
    border: none;
    background: #606060;
    color: white !important;
  }

  &.save {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    border: none;
    color: white !important;
  }

  &.save:hover {
    background: #f8f8f8;
    color: var(--primary-color) !important;
  }
  &.cancel:hover {
    background: #f8f8f8;
    color: var(--primary-color) !important;
  }
}
</style>
