<template>
  <div class="position-relative">
    <div class="position-relative">
      <label class="lable-header">
        {{ label }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <input
        :class="[
          `input-autocomplete form-control`,
          isValidate && `tw-border-red-500`,
        ]"
        autocomplete="off"
        role="combobox"
        list=""
        :id="id"
        :name="dataListID"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handleInput"
        ref="inputautocompletecustom"
        @focus="onFucus"
        @blur="focusOut"
      />

      <font-awesome-icon
        icon="chevron-down"
        class="arrow"
        @click="toggleClick()"
      />
    </div>
    <div
      class="loob-item"
      :class="absolute && 'absolute'"
      :id="dataListID"
      :style="`display:${styleList}`"
      v-if="optionsList.length > 0"
    >
      <div
        class="item"
        v-for="(item, index) in optionsList"
        :key="index"
        @click="selectItem(item)"
      >
        <p class="m-0 tw-text-gray-500">
          {{ item.name }}
        </p>
      </div>
    </div>

    <div class="loob-item nodata" :class="absolute && 'absolute'" v-else-if="showNodata">
      <p class="m-0">No data</p>
    </div>

    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">
        Please input text.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      required: false,
      default: false,
    },
    v: {
      required: false,
    },
    id: {
      required: true,
    },
    label: {
      required: false,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      default: "Please select value",
    },
    showValue: { required: false },
    valueField: { required: false },
    dataListID: { required: true },

    optionsList: { required: true, default: [] },
    isRequired: { required: false },

    absolute: {
      type: Boolean,
      required: false,
    },
    showNodata: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      currentFocus: -1,

      styleList: "none",
      selectData: null,
    };
  },

  methods: {
    selectItem(obj) {
      this.$refs.inputautocompletecustom.value = obj.name;
      this.selectData = obj;
      this.styleList = "none";
      this.$emit("input", obj.eventId || obj.id || obj.locationId);
    },

    onFucus() {
      this.styleList = "block";
    },

    focusOut() {
      setTimeout(() => {
        this.styleList = "none";
      }, 200);
    },

    toggleClick() {
      let input = document.getElementById(this.id);

      input.focus();
    },

    handleInput(event) {
      this.$emit("handleInput", event.target.value);
    },

    // new funciton
    clearInput() {
      document.getElementById(this.id).value = "";

      this.selectData = null;
    },
  },
};
</script>

<style lang="scss">
.loob-item {
  border: 1px solid #d8dbe0;
  // height: 100%;
  // min-height: 150px;
  overflow-x: hidden;
  overflow: scroll;

  &.absolute {
    position: absolute;
    top: 62px;
    z-index: 1;
    width: 100%;
    max-height: 150px;
  }

  &.nodata {
    padding: 5px 10px;
    text-align: center;
    color: darkgray;
    background: white;
    min-height: 30px;
    height: unset;
  }

  .item {
    padding: 5px 10px;
    background: white;

    &:hover {
      background: #d8dbe0;
      cursor: pointer;
    }
  }
}
.lable-header {
  font-size: 14px;
  font-weight: 700 !important;
  color: black;
}
datalist {
  position: absolute;
  background-color: white;
  border: 1px solid #d8dbe0;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 100%;
  padding: 5px;
  max-height: 10rem;
  z-index: 2;
  overflow-y: auto;
}

datalist[role="listbox"] option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 5px 0px;
  text-overflow: ellipsis;
  overflow: hidden;
}

datalist[role="listbox"] option:hover,
datalist[role="listbox"] option.active {
  background-color: lightblue;
}
.arrow {
  position: absolute;
  color: #636f83;
  top: 59%;
  right: 10px;
  // transform: translate(0, -50%);
}
</style>
