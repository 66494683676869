<template>
  <div class="date-picker">
    <v-date-picker
      v-model="date"
      is-expanded
      @update:from-page="changeDay"
      ref="datepicker"
    >
      <template #day-content="{ day, attributes }">
        <div class="tw-flex tw-flex-col tw-h-full tw-z-10 tw-overflow-hidden">
          <span
            class="day-label tw-text-sm tw-text-gray-900 tw-text-center tw-cursor-pointer tw-font-bold test tw-flex tw-items-center tw-justify-center"
            @click="selectDate(day)"
            :class="handleClassDay(day)"
            >{{ day.day }}</span
          >
          <div class="tw-flex-grow tw-overflow-y-auto tw-overflow-x-auto">
            <p
              class="tw-text-xs tw-leading-tight tw-rounded-sm tw-p-1 tw-mt-0 tw-mb-1 tw-text-center tw-font-bold"
              :class="handleClasstext(day)"
            >
              {{ handlePrice(day) }}
            </p>
          </div>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
export default {
  name: "CrateTransactionCalendar",
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  watch: {
    "form.eventGroupId"(val) {
      this.displayCalendar = false;
    },
    "form.eventId"(val) {
      if (val) {
        this.isDay = null;

        this.fetchCalendar();
      } else {
        this.displayCalendar = false;
      }
    },
  },
  mounted() {
    this.fetchCalendar();
  },
  data() {
    return {
      displayCalendar: false,
      date: null,

      attributes: [],

      // state active
      isDay: null,
    };
  },
  methods: {
    async getCalendar(month, year) {
      // clear data
      this.attributes = [];
      if (!this.form.eventId) return;

      const respone = await this.$services.eventgroup.getCalendar(
        month,
        year,
        1,
        this.form.eventId
      );
      if (respone.result == 1) {
        this.attributes = respone.detail;
        this.attributes[0] = {
          ...this.attributes[0],
        };
      }
    },
    selectDate(obj) {
      let { date, day, month, year } = obj;

      const isCheckDisable = this.attributes.find((x) => x.key == day);

      if (isCheckDisable.disabled == 1) {
        return;
      }

      this.date = date;
      this.isDay = `${day}/${month}`;

      this.$emit(
        "getRoundTime",
        `${year}-${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`
      );
    },
    fetchCalendar() {
      const today = new Date();
      let month = this.form.openEventDate
        ? this.form.openEventDate.month.toString()
        : (today.getMonth() + 1).toString();
      let year = this.form.openEventDate
        ? this.form.openEventDate.year.toString()
        : today.getFullYear();

      const datePicker = this.$refs.datepicker;
      setTimeout(async () => {
        await datePicker.move({ month: +month, year: +year });
      }, 500);
      this.getCalendar(month.padStart(2, "0"), year);
      this.displayCalendar = true;
    },
    changeDay(obj) {
      let { month, year } = obj;
      this.form.roundTimeEventId = null;
      this.$emit("clearOption");

      this.getCalendar(month, year);
    },
    handleClassDay(obj) {
      let newClass = [];

      if (obj === null) {
        newClass.push("dis");
      } else {
        let { day, month } = obj;

        const isCheckActive = `${day}/${month}`;

        if (isCheckActive == this.isDay) {
          newClass.push("active");
        }

        const isCheckDisable = this.attributes.find((x) => x.key == day);

        if (isCheckDisable && isCheckDisable.disabled == 1) {
          newClass.push("dis");
        }
      }

      newClass = newClass.join(" ");

      return newClass;
    },
    handlePrice(obj) {
      let { day } = obj;
      let price = "";

      const findDate = this.attributes.find((x) => x.key == day);

      if (findDate) {
        price = findDate.price;
      }

      return price;
    },
    handleClasstext(obj) {
      let { day } = obj;
      let newClass = [];

      const findDate = this.attributes.find((x) => x.key == day);

      if (findDate && findDate.disabled == 0) {
        newClass.push("tw-text-green-600");
      } else {
        newClass.push("tw-opacity-50");
      }

      newClass = newClass.join(" ");

      return newClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  min-height: 30px;
}
.date-picker {
  min-height: 326px;
  max-width: 80%;

  margin-left: 5%;
}
.text-day {
  font-size: 12px;
  opacity: 0.5;

  &.green {
    color: green;
    opacity: 1;
  }
}
.active {
  border-color: var(--secondary-color);

  border-radius: 50%;
  margin: auto;
  width: 32px;

  color: white;
  background-color: var(--secondary-color);
}
.dis {
  opacity: 0.5;
}

// ! override

.vc-highlights[data-v-4420d078] {
  display: none;
}
.vc-container,
.vc-container * {
  border: none;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}
</style>
