<template>
  <div>
    <AutocompleteCustom
      v-model="formCustomer.userId"
      ref="AutocompleteCustomUserLine"
      id="id"
      label="Line name"
      placeholder="Line name"
      dataListID="dataListID"
      valueField="id"
      showValue=""
      :optionsList="optionUser"
      @handleInput="handleInputSearch"
      absolute
      :v="v.userId"
      :isValidate="v.userId.$error"
    />

    <b-row
      ><b-col
        ><div class="my-3">
          <InputText
            v-model="formCustomer.firstname"
            class="input"
            textFloat="Name"
            placeholder="Name"
            type="text"
            name="Name"
            isRequired
            :v="v.firstname"
            :isValidate="v.firstname.$error"
          /></div></b-col
      ><b-col
        ><div class="my-3">
          <InputText
            v-model="formCustomer.lastname"
            class="input"
            textFloat="Surname"
            placeholder="Surname"
            type="text"
            name="Surname"
            isRequired
            :v="v.lastname"
            :isValidate="v.lastname.$error"
          /></div></b-col
    ></b-row>
    <b-row
      ><b-col
        ><div class="my-3">
          <InputText
            v-model="formCustomer.telephone"
            class="input"
            textFloat="Telephone"
            placeholder="Telephone"
            type="text"
            name="Telephone"
            isRequired
            :v="v.telephone"
            :isValidate="v.telephone.$error"
          /></div></b-col
      ><b-col
        ><div class="my-3">
          <InputText
            v-model="formCustomer.email"
            class="input"
            textFloat="Email"
            placeholder="Email"
            type="text"
            name="Email"
            isRequired
            :v="v.email"
            :isValidate="v.email.$error"
          /></div></b-col
    ></b-row>
  </div>
</template>

<script>
import AutocompleteCustom from "@/components/inputs/AutocompleteCustom";
export default {
  name: "CrateTransactionUserInfo",
  components: { AutocompleteCustom },
  props: {
    formCustomer: {
      type: Object,
    },
    v: {
      type: Object,
    },
  },
  watch: {
    "formCustomer.userId"(val) {
      if (val) {
        this.getUserLineDetail(val);
      }
    },
  },
  data() {
    return {
      optionUser: [],
      search: "",
    };
  },
  mounted() {
    this.getOptionUser(this.search);
  },
  methods: {
    async getUserLineDetail(userId) {
      const respone = await this.$services.user.getLineUserDetail(userId);

      if (respone.result == 1) {
        const { firstName, lastName, email, telephone } = respone.detail;
        this.formCustomer.firstname = firstName;
        this.formCustomer.lastname = lastName;
        this.formCustomer.email = email;
        this.formCustomer.telephone = telephone;
      }
    },
    async getOptionUser(val) {
      const respone = await this.$services.user.getLineUser(val);

      if (respone.result === 1) {
        this.optionUser = respone.detail;
      }
    },
    handleInputSearch(val) {
      setTimeout(() => {
        this.getOptionUser(val);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
